import React, {
  useState,
  // useCallback,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
  // useEffect,
} from "react";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { TracqPin } from "images";
import env from "environments/env";
import styles from "./station-map.module.scss";
import Slide from "@mui/material/Slide";
import locale from "localization";
// import { useMount } from "hooks";
import isValidCoordinates from "is-valid-coordinates";

const containerStyle = {
  width: "100%",
  height: "700px",
};

const StationMap = (
  {
    currentLocation,
    children,
    allowAccessLocation,
    fields,
    setIsValidCoordinates,
    hasSearchLocation,
  },
  ref
) => {
  const sliderRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.GOOGLE_MAP_API_KEY,
  });
  const [map, setMap] = useState(null);
  const [setZoom] = useState(null);
  const zoom = 5.7;

  const center = useMemo(() => {
    return {
      lat: 12.8797,
      lng: 121.774, // offset for panel info spacing
    };
  }, []);

  const defaultPosition = useMemo(() => {
    return {
      lat: 12.8797,
      lng: 121.774,
    };
  }, []);

  const [infoShow, setInfoShow] = useState(true);
  const [directions, setDirections] = useState(null);

  useImperativeHandle(ref, () => ({
    clearDirection() {
      setDirections(null);
    },
  }));

  let selectedLocation = currentLocation;
  if (hasSearchLocation) {
    if (fields?.stationLocation?.value.length > 0) {
      const stationlocation = fields?.stationLocation?.value;
      const geoMap = stationlocation.split(",");
      selectedLocation = { lat: Number(geoMap[0]), lng: Number(geoMap[1]) };
      if (
        isValidCoordinates.latitude(Number(geoMap[0])) &&
        isValidCoordinates.longitude(Number(geoMap[1]))
      ) {
        selectedLocation = { lat: Number(geoMap[0]), lng: Number(geoMap[1]) };
        setIsValidCoordinates(true);
      } else {
        selectedLocation = { lat: Number(geoMap[0]), lng: Number(geoMap[1]) };
        setIsValidCoordinates(false);
      }
    }
  }

  const markerSize = { anchor: selectedLocation ? 20 : 15, scale: selectedLocation ? 40 : 30 };
  return (
    isLoaded && (
      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        mapContainerStyle={containerStyle}
        center={center.lat && center.lng ? center : defaultPosition}
        zoom={zoom}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }}
        onZoomChanged={(props) => {
          if (map?.zoom) {
            setZoom(map?.zoom);
          }
        }}
      >
        {currentLocation && (
          <Marker
            key="current-location"
            position={selectedLocation}
            icon={{
              url: TracqPin,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(markerSize.anchor, markerSize.anchor),
              scaledSize: new window.google.maps.Size(markerSize.scale, markerSize.scale),
            }}
          />
        )}
        {children && (
          <>
            {!infoShow && (
              <div
                className={styles.openTab}
                onClick={() => {
                  setInfoShow(!infoShow);
                }}
              >
                <span className="icon-chevron-right" />
              </div>
            )}
            <Slide direction="right" in={infoShow} container={sliderRef.current} key="info-child">
              <div
                className={styles.infoContainer}
                style={{
                  top: "20px!important",
                }}
              >
                <div
                  className={styles.closeTab}
                  onClick={() => {
                    setInfoShow(!infoShow);
                  }}
                >
                  <span className="icon-chevron-left" />
                </div>
                {children}
              </div>
            </Slide>
          </>
        )}

        {!allowAccessLocation && (
          <div className={styles.notAllowAccessLocation} key="access-location-child">
            {locale.priceLocqDOesNotHaveAccessLocation}
          </div>
        )}

        {directions && (
          <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
        )}
      </GoogleMap>
    )
  );
};

export default forwardRef(StationMap);
