import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { getBrands } from "apis";
import locale from "localization";
import { useApi } from "hooks";

const SelectBrands = ({ placeholder = locale.searchOrSelect, disableAllOption, ...props }) => {
  const {
    request,
    loading,
    result = { brands: [], count: 0 },
  } = useApi({
    api: getBrands,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        order: "asc",
        status: "active",
        perPage: 10,
        ...params,
      });
    },
    [request]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result.count}
      loading={loading}
      formatRequest={(result) => {
        const { brands = [] } = result || {};
        const availableOptions = brands.filter((c) => c.status === "active");
        if (availableOptions.length > 0) {
          const options = availableOptions.map((d) => {
            return {
              label: d.brandName,
              value: d.brandId,
            };
          });
          return options;
        }
        return [];
      }}
      placeholder={placeholder}
      {...(!disableAllOption
        ? {
            allOption: [
              {
                label: "All",
                value: "all",
              },
            ],
          }
        : [])}
    />
  );
};

export default SelectBrands;
