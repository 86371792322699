import React, { useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";

import StationMap from "components/commons/station-map/station-map";
import { useMount } from "hooks";
import { Text } from "components/commons";
import { dateFormat, formatCurrency, formatNumber } from "utils";

import styles from "./station-pricing.module.scss";

import locale from "localization";
import { sortByKeyName } from "utils/object.utils";

const StationDetailsContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StationBanner = styled("div")(({ imageUrl }) => ({
  width: "100%",
  height: 250,
  background: `url(${imageUrl})`,
  backgroundSize: "100% auto",
  backgroundRepeat: "no-repeat",
}));

const StationDetails = styled("div")`
  padding: 10px 20px;
  width: 100%;
`;

const StationDetailsView = ({ stationData }) => {
  const { imageUrl, brand, capturedAt, address, priceCaptureProducts } = stationData;
  console.log(stationData);
  return (
    <StationDetailsContainer>
      <StationBanner imageUrl={imageUrl} />
      <StationDetails>
        <Text className={styles?.subBusinessId}>
          <locale.Populate text={locale.asOfDate} items={[dateFormat(capturedAt)]} />
        </Text>
        <Text strong style={{ margin: "15px 0px" }}>
          {brand?.brandName}
        </Text>
        <Text className={styles?.subBusinessId}>{address}</Text>
        <div style={{ display: "flex", gap: 100 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ margin: "15px 0px", color: "#95a2aa" }}>{locale.products}</Text>
            {priceCaptureProducts?.map((data) => (
              <Text>{data?.productName}</Text>
            ))}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ margin: "15px 0px", color: "#95a2aa" }}>{locale.pumpPrice}</Text>
            {priceCaptureProducts?.map((data) => (
              <Text>{formatCurrency(data?.price)}</Text>
            ))}
          </div>
        </div>
      </StationDetails>
    </StationDetailsContainer>
  );
};

const StationMapView = ({ stations: data = [] }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [allowAccessLocation, setAllowAccessLocation] = useState(true);
  // const [pinnedMap, setPinnedMap] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);

  const stations = useMemo(() => {
    const existing = [];
    const eAddress = [];
    return data
      .sort(sortByKeyName("priceCaptureId"))
      .reverse()
      .filter(({ latitude, longitude }) => {
        const geo = `${formatNumber(latitude, 12)}-${formatNumber(longitude, 12)}`;
        if (!existing.includes(geo)) {
          existing.push(geo);
          return true;
        }
        return false;
      })
      .filter(({ address }) => {
        if (!eAddress.includes(address)) {
          eAddress.push(address);
          return true;
        }
        return false;
      });
  }, [data]);

  const getCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position) {
          setCurrentLocation({
            lat: Number(position.coords.latitude),
            lng: Number(position.coords.longitude),
          });
        }
      },
      () => {
        setAllowAccessLocation(false);
      }
    );
  }, []);

  useMount(() => {
    getCurrentPosition();
  });

  return (
    <>
      <StationMap
        currentLocation={currentLocation}
        allowAccessLocation={allowAccessLocation}
        stations={stations}
        // pinnedMap={pinnedMap}
        setSelectedStation={setSelectedStation}
        // setPinnedMap={setPinnedMap}
        selectedStation={selectedStation}
      >
        {selectedStation !== null && <StationDetailsView stationData={stations[selectedStation]} />}
      </StationMap>
    </>
  );
};

export default StationMapView;
