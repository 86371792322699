import React, {
  useState,
  // useCallback,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
  // useEffect,
} from "react";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { TracqPin, CurrentLocation } from "images";
import env from "environments/env";
import styles from "./station-map.module.scss";
import Slide from "@mui/material/Slide";
import locale from "localization";
// import { useMount } from "hooks";

const containerStyle = {
  width: "100%",
  height: "700px",
};

const StationMap = (
  {
    stations,
    currentLocation,
    // pinnedMap,
    // setPinnedMap,
    children,
    setSelectedStation,
    allowAccessLocation,
    selectedStation,
  },
  ref
) => {
  const sliderRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env.GOOGLE_MAP_API_KEY,
  });
  const [map, setMap] = useState(null);
  const [mapZoom, setZoom] = useState(null);
  const zoom = 5.7;

  const center = useMemo(() => {
    return {
      lat: 12.8797,
      lng: 121.774, // offset for panel info spacing
    };
  }, []);

  const defaultPosition = useMemo(() => {
    return {
      lat: 12.8797,
      lng: 121.774,
    };
  }, []);

  const [infoShow, setInfoShow] = useState(true);
  const [directions, setDirections] = useState(null);

  // const pathFinder = useCallback(
  //   (goingTo) => {
  // if (currentLocation && goingTo) {
  //   const DirectionsService = new window.google.maps.DirectionsService();
  //   DirectionsService.route(
  //     {
  //       origin: new window.google.maps.LatLng(currentLocation?.lat, currentLocation?.lng),
  //       destination: new window.google.maps.LatLng(goingTo?.lat, goingTo?.lng),
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     },
  //     (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         // setPinnedMap({ lat: goingTo?.lat, lng: goingTo?.lng });
  //         map.panTo(new window.google.maps.LatLng(goingTo?.lat, goingTo?.lng));
  //         setDirections(result);
  //       } else {
  //         console.error(`error fetching directions ${result}`);
  //       }
  //     }
  //   );
  // }
  //   },
  //   [currentLocation, map]
  // );

  useImperativeHandle(ref, () => ({
    // showDirection(goingTo) {
    //   pathFinder(goingTo);
    // },
    clearDirection() {
      setDirections(null);
    },
  }));

  // map should be initiated before using DirectionsService()
  // useEffect(() => {
  //   if (window.google && pinnedMap) {
  //     // pathFinder(pinnedMap);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.google]);

  console.log(zoom);
  return (
    isLoaded && (
      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        mapContainerStyle={containerStyle}
        center={center.lat && center.lng ? center : defaultPosition}
        zoom={zoom}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }}
        onZoomChanged={(props) => {
          if (map?.zoom) {
            setZoom(map?.zoom);
          }
        }}
      >
        {currentLocation && (
          <Marker
            key="current-location"
            position={currentLocation}
            icon={{
              url: CurrentLocation,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(20, 20),
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        )}
        {stations?.map(({ brand, ...station }, index) => {
          const markerKey = station.latitude + station.longitude;
          // const selectedMarker = pinnedMap?.lat + pinnedMap?.lng;
          const isSelected = selectedStation === index;

          const isZoomed = mapZoom >= 12;
          const markerSize = { anchor: isSelected ? 20 : 15, scale: isSelected ? 40 : 30 };
          const stationLocations = { lat: station.latitude, lng: station.longitude };
          let iconUrl = isSelected
            ? {
                url: TracqPin,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(markerSize.anchor, markerSize.anchor),
                scaledSize: new window.google.maps.Size(markerSize.scale, markerSize.scale),
              }
            : {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "orange",
                fillOpacity: 1,
                strokeColor: "orange",
                strokeOpacity: 1,
                strokeWeight: 1,
                scale: isZoomed ? 10 : 4,
              };
          return (
            <Marker
              key={markerKey}
              position={stationLocations}
              onClick={() => {
                // if (!isSelected) {
                // console.log(stationLocations);
                // setPinnedMap(stationLocations);
                console.log(index);
                setSelectedStation(index);
                map.setZoom(17);
                map.panTo(new window.google.maps.LatLng(station?.latitude, station?.longitude));
                // map.setPinnedMap(stationLocations);
                // }
              }}
              icon={iconUrl}
              label={
                isZoomed
                  ? {
                      text: brand?.brandName,
                      color: "#753bbd",
                      className: styles.markerLabel,
                    }
                  : ""
              }
            >
              <div>{brand?.brandName}</div>
            </Marker>
          );
        })}
        {children && (
          <>
            {!infoShow && (
              <div
                className={styles.openTab}
                onClick={() => {
                  setInfoShow(!infoShow);
                }}
              >
                <span className="icon-chevron-right" />
              </div>
            )}
            <Slide direction="right" in={infoShow} container={sliderRef.current} key="info-child">
              <div
                className={styles.infoContainer}
                style={{
                  top: "20px!important",
                }}
              >
                <div
                  className={styles.closeTab}
                  onClick={() => {
                    setInfoShow(!infoShow);
                  }}
                >
                  <span className="icon-chevron-left" />
                </div>
                {children}
              </div>
            </Slide>
          </>
        )}

        {!allowAccessLocation && (
          <div className={styles.notAllowAccessLocation} key="access-location-child">
            {locale.priceLocqDOesNotHaveAccessLocation}
          </div>
        )}

        {directions && (
          <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />
        )}
      </GoogleMap>
    )
  );
};

export default forwardRef(StationMap);
