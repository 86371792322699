import { Image, Text, PopOverMenu, Button } from "components/commons";
import { dateTimeFormat, formatCurrency } from "utils";
import styles from "./station-pricing.module.scss";
import { capitalizeWord } from "utils/text.utils";
import locale from "localization";

export const mapDataToList = ({ priceCaptures = {}, showImage }) => {
  const {
    brand = {},
    capturedAt,
    priceCaptureId,
    station,
    province,
    city,
    priceCaptureProducts,
    priceCaptureStations,
    imageUrl,
    email,
  } = priceCaptures;
  const { brandName = "" } = brand || {};
  const optionsData = priceCaptureStations?.map((data) => {
    return {
      content: <Text>{capitalizeWord(data.name)}</Text>,
    };
  });
  const optionsCompetitor = [...optionsData];
  return {
    brandName,
    uploadId: (
      <>
        <Text>{priceCaptureId}</Text>
        <Text className={styles?.subBusinessId}>{dateTimeFormat(capturedAt)}</Text>
      </>
    ),
    stationName: station?.name,
    competitorOf:
      priceCaptureStations.length > 2 ? (
        <PopOverMenu position="right" options={optionsCompetitor} hasHeader={true}>
          <Button style={{ cursor: "pointer" }} link>
            {locale.viewAll}
          </Button>
        </PopOverMenu>
      ) : (
        <>
          {priceCaptureStations?.map((data) => (
            <Text>{capitalizeWord(data.name)}</Text>
          ))}
        </>
      ),
    province,
    city,
    product: (
      <>
        {priceCaptureProducts.map((data) => (
          <Text>{capitalizeWord(data.productName)}</Text>
        ))}
      </>
    ),
    pumpPrice: (
      <>
        {priceCaptureProducts.map((data) => (
          <Text>{formatCurrency(data.price)}</Text>
        ))}
      </>
    ),
    uploaderEmail: <>{email ? <Text>{email}</Text> : <Text>{locale?.noEmail}</Text>}</>,
    priceCapture: (
      <>
        <Image src={imageUrl} className={styles?.imageSize} onClick={() => showImage(imageUrl)} />
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { areaGroup = {}, province = {}, city = {}, brand = {}, ...fs } = filterState;
  const selectedBrands = brand?.value?.map((item) => {
    return item.value;
  });
  return {
    ...fs,
    areaGroup: areaGroup.isAll ? "" : areaGroup?.value?.join(","),
    province: province.isAll ? "" : province?.value?.join(","),
    city: city.isAll ? "" : city?.value?.join(","),
    brand: brand.isAll ? "" : selectedBrands.join(","),
  };
};
