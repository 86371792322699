import React, {
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { useForm, useApi, useModal } from "hooks";
import { FormMode, Path } from "enums";
import { initialState as formState } from "./station-details.state";
import StationDetailsFormModule from "./station-details-form.module";
import styles from "./station-details.module.scss";
import locale from "localization";
import { Loader } from "components/commons";
import { handleRequest } from "utils";
import { ConfirmModal } from "components/modals";
import { useHistory } from "react-router-dom";
import { createStation, updateStation, getStations } from "apis/station.api";
import { stationFormPost, stationFormGet } from "./station-form.utils";
import { capitalizeAll } from "utils/text.utils";

const StationDetailsModule = ({ pageMode, stationData, stationCount, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [formValid, setFormValid] = useState(false);
  const [details, setDetails] = useState(null);
  const [isValidCoordinates, setIsValidCoordinates] = useState(false);

  const { request: getStationsRequest, loading: loadingStations } = useApi({
    api: getStations,
    pageError: true,
  });

  const form = useMemo(() => {
    let initialState = {};
    if (stationData) {
      const data = stationData;
      if (Object.keys(data).length > 0) {
        // const { competitorProducts } = data;
        console.log("meow", data);
        initialState = stationFormGet(data);
        setDetails({ ...data, province: data?.area });
      } else {
        setDetails({});
      }
    }
    return formState(initialState);
  }, [stationData]);

  const confirmModal = useModal();
  const { show, close } = confirmModal;
  const history = useHistory();

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    modifyForm,
    clearForm,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  const formAttributeValidation = useCallback(
    (formVal) => {
      const errors = [];

      let validStationCode;
      let validStationLocation;
      let validStationDetails;
      let validCoordinates;
      if (fields.stationCode.value === "") {
        validStationCode = false;
      } else {
        validStationCode = true;
      }

      if (fields.stationLocation.value === "") {
        validStationLocation = false;
      } else {
        validStationLocation = true;
      }
      if (!isValidCoordinates) {
        validCoordinates = false;
      } else {
        validCoordinates = true;
      }

      if (formVal) {
        if (Object.keys(formVal).length > 0) {
          validStationDetails = true;
        } else {
          validStationDetails = false;
        }
      }

      errors.push(validStationCode, validStationLocation, validStationDetails, validCoordinates);
      const allValid = errors.every((element) => element === true);

      return allValid;
    },
    [fields?.stationCode.value, fields?.stationLocation.value, isValidCoordinates]
  );

  const addRequest = useApi({
    api: createStation,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateStation,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      stationId: stationData.stationId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;
    handleRequest(
      async () => {
        close();
        await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );
        clearForm();
        if (addMode) {
          show({
            title: locale.exclamatedSuccess,
            content: (
              <locale.Populate
                text={locale.successfullyAddStation}
                items={[<b>{capitalizeAll(params.name)}</b>]}
              />
            ),
            secondary: {
              text: locale.goBackStationList,
              onClick: () => {
                setDetails({});
                history.push(Path.StationList);
              },
            },
            primary: {
              text: locale.addNewSeaOilStation,
              onClick: () => {
                setDetails({});
                close();
              },
            },
          });
        } else {
          await getStationsRequest();
          show({
            title: locale.exclamatedSuccess,
            content: locale.detailsHaveBeenUpdated,
            primary: {
              text: locale.gotIt,
              onClick: () => {
                history.push(Path.StationList);
              },
            },
          });
        }
      },
      {
        S1000: () => {
          applyFieldErrors({
            stationCode: locale.stationAlreadyExist,
          });
        },
      }
    );
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();
    const errorRes = formAttributeValidation(details);
    let payload = stationFormPost(currentFormValues, details);
    if (errorRes) {
      show({
        title: addMode ? locale.addNewSeaOilStationQuestion : locale.saveChangesQuestion,
        content: addMode ? (
          <>
            <locale.Populate
              text={locale.youAreAboutToAddStation}
              items={[<b>{capitalizeAll(payload.name)}</b>]}
            />
            <br />
            <locale.Populate text={locale.doYouWantToProceedQuestion} items={[]} />
          </>
        ) : (
          locale.areYouSureYouWantToSaveStation
        ),
        secondary: {
          text: addMode ? locale.Cancel : locale.continueEditing,
        },
        primary: {
          text: addMode ? locale.addSeaOilStation : locale.saveChanges,
          onClick: () => {
            setDetails({});
            submit(payload);
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      setDisableUpdate(!formAttributeValidation);
    }
  }, [
    editMode,
    isFormSubmittable,
    loading,
    setDisableUpdate,
    setUpdateLoading,
    formAttributeValidation,
  ]);

  useEffect(() => {
    if (!editMode) {
      if (isFormSubmittable === true && formAttributeValidation(details) === true) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, [editMode, isFormSubmittable, formAttributeValidation, details]);

  const detailsFormProps = {
    viewMode,
    addMode,
    editMode,
    fields,
    isFormSubmittable,
    loading,
    modifyField,
    submitForm,
    modifyForm,
    handleSubmit,
    formAttributeValidation,
    getStationsRequest,
    applyFieldErrors,
    setDetails,
    details,
    formValid,
    getFormValues,
    setIsValidCoordinates,
  };

  return (
    <div>
      <Loader open={loadingStations} />
      {!loadingStations && (
        <div className={styles.container}>
          {!viewMode && (
            <>
              <ConfirmModal {...confirmModal} />
            </>
          )}
          <StationDetailsFormModule {...detailsFormProps} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(StationDetailsModule);
